import React from "react"
import { navigate } from "gatsby"
import { window } from 'ssr-window';
import { getUser, isLoggedIn } from "../services/auth"
import { Link } from '@reach/router'

import Layout from "../components/Layout"
import Container from "../components/Container"

export default function Home() {
  return (
    <Layout>
      <Container>
        <p><Link to="/app/login">こちら</Link>から役割を入力してください</p>
      </Container>
    </Layout>
  )
}
